import { Component, Prop } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';

import style from './MobileNavigation.scss';
import { Link } from '~/components/molecules';
import { Icons } from '~/components/atoms';
import cztLogoCz from '~/assets/cztLogo-white_CZ.svg';
import cztLogoEn from '~/assets/cztLogo-white_EN.svg';
import {
  isNavigationGroup,
  isNavigationLink,
  NavigationElement,
  NavigationLink,
} from '~/utils/navigation';
import { supportedLocales } from '~/app/localization';
import { LanguageLocaleInterface } from '~/utils/localization';
import RouterModule from '~/app/core/store/modules/RouterModule';
import { getModule } from 'vuex-module-decorators';

interface MobileNavigationInterface {
  items: NavigationElement[];
}

const rootClass: string = 'czt-mobile-navigation';

@Component({
  style,
})
export default class MobileNavigation extends VueComponentMixin<
  MobileNavigationInterface,
  PrefetchComponent
>(Prefetch) {
  @Prop({ required: true, type: Array })
  public items!: NavigationElement[];

  protected get routerStore(): RouterModule {
    return getModule(RouterModule, this.$store);
  }

  protected menuVisible: boolean = false;

  private closeMenu() {
    this.menuVisible = false;
    this.$emit('closed');
  }

  public render() {
    return (
      <v-menu
        content-class={`${rootClass}`}
        close-on-content-click={false}
        v-model={this.menuVisible}
        min-width='100%'
        eager
        scopedSlots={{
          activator: (scope: any) => {
            return (
              <a
                class={`${rootClass}__button`}
                {...{
                  on: scope.on,
                }}
              >
                <Icons.common.Menu size={2.5} />
              </a>
            );
          },
        }}
      >
        <v-list
          class={`${rootClass}__list overflow-y-auto`}
          min-width='260px'
          max-height='100vh'
        >
          {this.renderMenuTitle()}
          {this.items.map((item, index) =>
            this.renderMenuItems(item, index, 0)
          )}
          {this.renderLanguageSwitch()}
        </v-list>
      </v-menu>
    );
  }

  protected renderMenuTitle(): JSX.Element {
    return (
      <v-list-item>
        <v-list-item-content>
          <Link url='~/'>
            <v-img
              max-width='190px'
              src={this.$i18n.locale === 'en-US' ? cztLogoEn : cztLogoCz}
              onclick={this.closeMenu}
            />
          </Link>
          <a onClick={this.closeMenu} class={`${rootClass}__close`}>
            <Icons.common.Close size={1.8} />
          </a>
        </v-list-item-content>
      </v-list-item>
    );
  }

  protected renderMenuItems(
    item: NavigationElement,
    index: number,
    depth: number
  ): JSX.Element {
    const classNames = [`${rootClass}__item`];
    if (depth > 0) {
      classNames.push(
        `${rootClass}__item--sub`,
        `${rootClass}__item--sub--${depth}`
      );
    }

    if (isNavigationGroup(item)) {
      return (
        <v-list-group
          key={index}
          class={`${rootClass}__group`}
          sub-group={depth > 0}
        >
          <v-list-item-title slot='activator' class={`${rootClass}__title`}>
            {item.title}
          </v-list-item-title>
          {item.items.map((child: NavigationElement, childIndex: number) =>
            this.renderMenuItems(child, childIndex, depth + 1)
          )}
        </v-list-group>
      );
    }

    return (
      <v-list-item key={index} class={classNames}>
        <div onclick={this.closeMenu}>
          <Link url={item.url}>{item.title}</Link>
        </div>
      </v-list-item>
    );
  }

  protected renderLanguageSwitch(): JSX.Element | undefined {
    const languageSwitchItems: NavigationElement[] = supportedLocales
      .filter(
        (item: LanguageLocaleInterface) =>
          item.public && item.slug !== this.$i18n.locale
      )
      .map((item: LanguageLocaleInterface) => {
        return {
          title: item.name,
          url: `/${item.slug}`,
        };
      });
    if (languageSwitchItems.length < 1) {
      return;
    }
    const classNames = [`${rootClass}__item`, `${rootClass}__item--sub`];

    return (
      <v-list-group
        key={`mobileLanguageSwitchMenu-${this.$i18n.locale}`}
        class={`${rootClass}__group`}
      >
        <v-list-item-title slot='activator' class={`${rootClass}__title`}>
          {this.$t('app.common.language')}
        </v-list-item-title>
        {languageSwitchItems
          .filter(isNavigationLink)
          .map((item: NavigationLink, index: number) => (
            <v-list-item key={index} class={classNames}>
              <router-link to={item.url} nativeOnClick={this.closeMenu}>
                {item.title}
              </router-link>
            </v-list-item>
          ))}
      </v-list-group>
    );
  }
}
